<template>
  <div class="navBar">
    <img alt="Vue logo" src="../assets/logo.jpg" class="logo">
    <ul>
      <li><router-link :to="{name: 'home'}">Home</router-link></li> 
      <li><router-link :to="{name: 'gallery'}">Gallery</router-link></li> 
      <li><router-link :to="{name: 'services'}">Services</router-link> </li>
      <li><router-link :to="{name: 'contactUs'}">Contact Us</router-link></li> 
    </ul>
  </div>
    
  
      
</template>

<script>
export default {

}
</script>

<style scoped>
.logo {
  width: 120px;
  height: 50px;
  margin-left: 10px;
  border: 1px solid white;
  align-self: center;
  border-radius: 10px;
  cursor: pointer;
}

.navBar {
  width: 100%;
  height: 15px;
  margin: auto;
  padding: 35px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: black;
  border-radius: 10px;
}
.navBar ul li {
  list-style: none;
  display: inline-block;
  margin: 0 30px;
  position: relative;

}

.navBar ul li a {
  font-weight: bold;
  font-size: 2vw;
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.navBar ul li a::after {
  content: '';
  height: 3px;
  width: 0;
  background: #009688;
  left: 0;
  bottom: -10px;
  transition: 0.5s;
  position: absolute;
}
.navBar ul li a:hover::after {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .navBar ul li {
    display: block;
  }
  
  .navBar {
    height: 40px;
  }
}

</style>