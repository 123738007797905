<template>
  <NavBar/>
  <div class="container">
    <div class="box">
      <div class="imgBx">
        <img src="../views/images/Straight teeth simple gear.jpg">
      </div>
      <div class="content">
        <div>
          <h2>Straight teeth simple gear</h2>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi accusamus molestias quidem iusto.
          </p>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="imgBx">
        <img src="../views/images/Helical teeth simple gear.jpg">
      </div>
      <div class="content">
        <div>
          <h2>Helical teeth simple gear</h2>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi accusamus molestias quidem iusto.
          </p>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="imgBx">
        <img src="../views/images/Straighten teeth only rack.jpg">
      </div>
      <div class="content">
        <div>
          <h2>Straighten teeth only rack</h2>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi accusamus molestias quidem iusto.
          </p>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="imgBx">
        <img src="../views/images/Straighten teeth racks.jpg">
      </div>
      <div class="content">
        <div>
          <h2>Straighten teeth racks</h2>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi accusamus molestias quidem iusto.
          </p>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="imgBx">
        <img src="../views/images/Helical teeth gear and rack.jpg">
      </div>
      <div class="content">
        <div>
          <h2>Helical teeth gear and rack</h2>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi accusamus molestias quidem iusto.
          </p>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="imgBx">
        <img src="../views/images/Straight teeth bevel gear.jpg">
      </div>
      <div class="content">
        <div>
          <h2>Straight teeth bevel gear</h2>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi accusamus molestias quidem iusto.
          </p>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="imgBx">
        <img src="../views/images/Helical teeth bevel gear.jpg">
      </div>
      <div class="content">
        <div>
          <h2>Helical teeth bevel gear</h2>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi accusamus molestias quidem iusto.
          </p>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="imgBx">
        <img src="../views/images/Helical teeth bevel gear 2.jpg">
      </div>
      <div class="content">
        <div>
          <h2>Helical teeth bevel gear </h2>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi accusamus molestias quidem iusto.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "../components/NavBar"
export default {
    components: {
      NavBar,
    }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}
body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.container {
  position: relative;
  max-width: 1160px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  transform-style: preserve-3d;
  perspective: 500px;
  margin: auto;
}
.container .box {
  position: relative;
  width: 275px;
  height: 275px;
  background: #000;
  transition: 0.5s;
  transform-style: preserve-3d;
  overflow: hidden;
  margin-right: 15px;
  margin-top: 45px;
  border: 2px solid black;
  border-radius: 10px;
}
.container:hover .box {
  transform: rotateY(25deg);
}
.container .box:hover ~ .box {
  transform: rotateY(-25deg);
}
.container .box:hover {
  transform: rotateY(0deg) scale(1.25);
  z-index: 1;
  box-shadow: 0 25px 40px rgba(0,0,0,0.5);
}
.container .box .imgBx {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.container .box .imgBx:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg,#f00,#000);
  z-index: 1;
  opacity: 0;
  transition: 0.5s;
  mix-blend-mode: multiply;
}
.container .box:hover .imgBx:before {
  opacity: 1;
}
.container .box .imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.container .box .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  padding: 20px;
  align-items: flex-end;
  box-sizing: border-box;
}
.container .box .content h2 {
  color: #fff;
  transition: 0.5s;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 20px;
  transform: translateY(200px);
  transition-delay: 0.3s;
}
.container .box:hover .content h2 {
  transform: translateY(0px);
}
.container .box .content p {
  color: #fff;
  transition: 0.5s;
  font-size: 14px;
  transform: translateY(200px);
  transition-delay: 0.4s;
}
.container .box:hover .content p {
  transform: translateY(0px);
}
</style>