<template>
  <router-view/>
</template>

<style >
*{
  padding: 10px auto;
  margin: 10px auto;
  box-sizing: border-box;
}

</style>
