<template>
  <NavBar/>
  <div class="home">
    <div class="content">
      <h1> Make any type of gear from <br> Kareem and Sons Mechanical Works. </h1>
      <p> We are making every type of gears,</p>
      <p>if you want to make any gear please visit our shop.</p>
    </div>
  </div>
</template>

<script>
import NavBar from '../components/NavBar'
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {
    NavBar,
  }
}
</script>
<style scoped>
*{
  margin: 0;
  padding: 0;
  border: none;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.home {
  margin-top: 10px;
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(rgba(0,0,0,0.75),rgba(0,0,0,0.75)) ,url('images/background.jpg');
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  
}
.content {
  width: 100%;
  position: absolute;
  text-align: center;
  top: 50%;
  color: white
}
.content h1 {
  font-size: 5vw;
  margin: 40px auto;


}

.content p {
  margin-top: 5px ;
  font-size: 3vw;
  line-height: 25px;
}

</style>